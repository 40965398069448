<template>

  <div style="background-color: var(--white);">
    <br />
    <div style="color: var(--darkBackground); padding: 5px">
      <h4>PRIVACY POLICY</h4>
      <p>
        <strong>
          Tucheze.com will treat the data that you provide us with as strictly private and it shall only be used in
          accordance with this privacy statement by us, by a member of our Group and/or by a selected third party who
          complies with our high standards of data protection in order to manage the personal account which you open
          with us (“Account”), to create customer profiles, to ensure the security of the Site and to enable you to use
          our services.
        </strong>
      </p>
      <h5> Personal Data</h5>
      <p>
        All references to ‘Personal Data’ in this Privacy Policy refer to any personal information about a natural
        person which enables that individual to be identified directly or indirectly by reference to the data provided.
      </p>
      <p>
        Specifically, we collect the following information when you open an Account with us: first name, surname,
        gender, date of birth, place of birth, postal address, email address, phone number, currency, a valid original
        copy of an official document bearing his names and photographs (passport or ID card).
      </p>
      <p>
        You will also be required to provide login details of your choice, namely a username, password which will be
        kept by us. We additionally require you to provide us with a payment method and payment details (such as bank
        transfer, credit card or another acceptable means of payment) in order that you can credit your Account.
      </p>
      <p>
        We require the above information in order to ensure that you can identify yourself when using the Site, to
        ensure that you are of legal age to use our services, to improve our services to you and to keep you informed
        regarding any of our services which may be of interest to you.
      </p>
      <p>
        You also have the right to access your personal data at any time in order to make alterations to any personal
        information that may have changed or become obsolete.
      </p>
      <p>
        Should you believe that any personal information we hold for you is incorrect, please email our customer
        services on <a href="mailto: help@tucheze.com">help@tucheze.com</a> and we will amend any information found to
        be incorrect.
      </p>


      <h5> Personal Data</h5>
      <p>
        By registering and/or signing up for an Tucheze.com account or updating your Tucheze.com account, and using an
        account or this service, players agree that Tucheze.com, and its business partners may use the details provided
        by the player to contact them about its products and services and for marketing and promotional purposes.
      </p>
      <p>
        To stop receiving promotional messages from Tucheze.com, SMS the word ‘STOP’ to the Company short code
        <strong>29028</strong>. Should there be any transaction after the text please contact customer care on <a
          href="tel:+254 716 797777"> +254 716 797777</a> immediately.
      </p>

      <h5>Use of personal data</h5>
      <p>
        Once your personal data is submitted to us, it shall be kept securely on our database.
      </p>

      <p>
        We use the email address which you have provided us with to send you an email welcoming you to Tucheze.com.
      </p>

      <p>
        We may send you emails from time to time containing information ranging from special promotions to technical and
        other information relating to our services.
      </p>
      <p>
        Should you wish to unsubscribe from our email mailing list, you can do this easily by contacting us.
      </p>
      <p>
        As part of our partnership with commercial banks and/or the companies in charge of verifying the authenticity of
        credit card or electronic payment processing data, your details are at the disposal of these establishments.
      </p>
      <p>
        However, these establishments are only entitled to use such information for legal investigation purposes.
      </p>
      <p>
        We may also enter into partnerships with data processors and companies which provide security, age verification
        and identification verification services (including, without limitation, financial institutions, age
        verification, and credit reference agencies) in order to improve the efficiency and security of the Site and/or
        to comply with relevant regulations.
      </p>
      <p>
        These companies may have access to your personal data and may keep record in order that they can process
        necessary information data and maintain the security of the Site.
      </p>
      <p>
        The companies that we choose to carry out these functions have strict policies regarding privacy of personal
        data, although we accept no responsibility for the privacy policies of those companies.
      </p>
      <p>
        We will not disclose the amount of your winnings or losses to any person or third party other than to you unless
        we are required by law to disclose such information to competent authorities or for legal purposes.
      </p>
      <p>
        It is therefore incumbent upon you to declare your winnings to the relevant authorities if you reside in a
        country where such gains are subject to taxation.
      </p>
      <p>
        We reserve the right to pass on to public or other relevant authorities, customers’ personal data if necessary
        or required in the course of any legal investigation.
      </p>
      <p>
        The personal data submitted by our customers may be stored and/or processed for the purposes set forth above in
        this Privacy Statement in any country in which we or our affiliates, suppliers, subsidiaries or agents maintain
        facilities.
      </p>
      <p>
        By accepting this Privacy Policy, you give your consent to the transfer of your personal data outside of your
        country.
      </p>
      <p>
        Tucheze.com is committed to taking all necessary steps to ensure that whenever personal data is transmitted,
        such transmission takes place solely within the Group and the Group’s select partners in which companies the
        standards of privacy and confidentiality should be of an acceptable standard, irrelevant of location.
      </p>
      <p>
        Tucheze.com shall in no manner whatsoever be held responsible for the manner in which third parties process
        and/or store the personal data of our customers.
      </p>
      <p>
        Tucheze.com shall only be responsible for its own Privacy Policy and it does not warrant the standard of the
        privacy policies of any of the third parties who may have links placed on our Sites.
      </p>
      <p>
        We strongly recommend that when you access a new page via a link on our Sites which takes you to an external
        website, you take time to read through the privacy policy of the new website which you enter.
      </p>

      <h5>Retention of your personal data</h5>
      <p>
        As our customer, you or we can close your Account with us at any time. Following closure of your Account, we
        will retain your personal data on record for as long as required by law.
      </p>
      <p>
        We will only use your data should such use be required by competent authorities in cases of fraud or
        investigation into any other illegal activity.
      </p>
      <p>
        This will be the sole exception to use of your personal data until its complete deletion from our records.
      </p>

      <h5>
        Protection of minors
      </h5>
      <p>
        The online betting and gaming services that we offer are strictly prohibited for persons less than 18 years of
        age or under the age of legal consent for using online betting and gaming services under the laws of the
        jurisdiction in which the person is located.
      </p>
      <p>
        Consequently, minors may not register and play on our Site. We collect proof of identification from our
        customers as part of our age verification process.
      </p>
      <p>
        Any minor who is found to be gambling on Tucheze.com shall have his/her Account immediately blocked. Tucheze.com
        reserves the right to conduct a security review to validate your identity and age. Acceptance of our Privacy
        Policy authorizes us to conduct such security reviews supplied by you against third party databases.
      </p>

      <h5>
        Security
      </h5>
      <p>
        Tucheze.com uses its best endeavors to protect the confidentiality of your personal data and to prevent any
        unauthorized external or internal users from accessing such information without your consent.
      </p>
      <p>
        However, Tucheze.com shall take no responsibility whatsoever for events beyond its control inclusive of, but not
        limited to, acts of god or natural disasters and including power cuts and strikes.
      </p>
      <p>
        We are sure that, as our customer, you understand the evolving nature of our business due to continuous
        technological innovation and, although we endeavor to do our best to ensure the protection and privacy of your
        personal data at all times, we cannot guarantee that the performance of our service will be free from error at
        all times. Should any such error have an effect or consequence on the privacy of your personal data, we shall
        not be liable in any manner whatsoever in damages or otherwise.
      </p>
      <p>
        We strongly recommend that you do not divulge your personal data to strangers or third parties during live chat.
        Remember that your personal data is sensitive and it’s important to stop and think before passing on personal
        information.
      </p>

      <h5>
        Modifications
      </h5>
      <p>
        It may become necessary, at any time, for us to make modifications to the Site or to the Terms and Conditions or
        Privacy Policy governing its services.
      </p>
      <p>
        The purpose of such modifications may be to further simplify user processes or to further reinforce security
        measures in order to upgrade to future technological innovations.
      </p>
      <p>
        In such cases, customers previously registered on our Site will keep their user name no modifications will be
        required on their part.
      </p>
      <p>
        Continued use of our Site by the members after any modifications to the Site, the Terms and Conditions or
        Privacy Policy will be considered as their acceptance thereof.
      </p>
      <p>
        It is the responsibility of each customer to regularly check the, Terms and Conditions and Privacy Policy for
        updates.
      </p>
      <p>
        <i>This Privacy Policy is effective from 1st APRIL 2024</i>
      </p>

    </div>


    <bottom-navigation active-bets="0" page="login"></bottom-navigation>

  </div>

</template>

<script>

import BottomNavigation from "@/components/BottomNavigation";

export default {
  name: 'privacy',
  components: {

    BottomNavigation
  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage", "responsible");
    this.reloadProfile();

  }

}
</script>